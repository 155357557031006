#nav {
	font-size: 1.2em;
	height: var(--nav-height, 4rem);
	padding: 0;
	gap: 6px;
	top: 0;
	overflow: visible;
}

#nav-container {
	display: inline-flex;
	flex-direction: row;
	height: 100%;
}

#nav > .nav-btn {
	background-color: transparent;
	color: inherit;
	border: none;
}

#nav > .nav-link, #nav > .nav-btn:not([hidden]) {
	padding: 0.4rem 0.8rem;
	max-width: 270px;
	transition: background-color 600ms ease-in-out;
	flex-grow: 1;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.nav-link:hover, #nav > .nav-btn:hover, .nav-link.active {
	background-color: var(--button-primary-active-background);
}

.nav-link.active, #nav > .nav-btn:active {
	border-bottom: 0.2em solid currentColor;
}

#nav > :empty {
	display: none;
}

a.disabled {
	pointer-events: none;
	cursor: not-allowed;
}

@media (max-width: 550px) {
	#nav .nav-link-label, #nav .nav-item-br {
		display: none;
	}
}

@supports (display: flex) {
	.nav-link > .nav-item-br {
		display: none;
	}
}
