#footer {
	padding: 1.4em;
	background-color: #232323;
	color: #fafafa;
}

@media (max-width: 800px) {
	#footer {
		text-align: center;
	}
}

.social-links, .footer-link {
	font-size: 1.3rem;
	padding: 0.4em;
}

.social-link {
	padding: 0.4em;
}

.social-link .social-icon {
	width: 48px;
	vertical-align: middle;
}

.social-links .cols {
	padding: 0 1.5rem;
}

.social-links a svg {
	display: inline-block;
	transition: transform 600ms cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.social-links a:hover svg {
	transform: rotate(1turn) scale(1.3);
}
