#menu-accessible-nav:focus-within {
	width: 100%;
	gap: 8px;
	flex-wrap: wrap;
}

.menu-section :target {
	scroll-margin-top: calc(var(--nav-height, 3.5rem) + 3.1em);
}

.menusection-header.top {
	top: var(--nav-height, 3.5rem);
	background: #710202;
	color: #fafafa;
	padding: 0.2rem;
}

.menusection-description {
	padding: 0.8em;
	border-bottom: 1px dashed var(--accent-color);
	margin-bottom: 0.6em;
	font-family: "Alice";
	font-style: italic;
	text-align: center;
	background-color: #ddcbb2;
	color: #393939;
}

.food-menuitem {
	display: grid;
	grid-template-areas: "image name share" "image description description" "image offers diets";
	grid-template-rows: auto 1fr auto;
	grid-template-columns: 320px 1fr auto;
	grid-gap: 0.6em;
	padding: 0.4em;
	margin-bottom: 0.4rem;
	min-height: calc(213px + 1.8em)
}

@media (min-width: 800px) {
	.food-menuitem:not(:hover) .share-menuitem {
		visibility: none;
	}
}

.share-menuitem.btn.btn-outline {
	grid-area: share;
	border-radius: 12px;
	color: var(--accent-color);
	background-color: transparent;
	border: 2px solid currentColor;
	transition: opacity 400ms ease-in-out;
}

@media (prefers-color-scheme: dark) {
	:root:not([data-theme="light"]) .share-menuitem.btn.btn-outline {
		color: #cdcdcd;
	}
}

:root[data-theme="dark"] {
	.share-menuitem.btn.btn-outline {
		color: #cdcdcd;
	}
}

.menuitem-name {
	margin: 0;
	grid-area: name;
}

.menuitem-description {
	grid-area: description;
}

.menuitem-image {
	grid-area: image;
}

.menuitem-offers {
	grid-area: offers;
}

.menuitem-diets {
	grid-area: diets;
}

.menu-container .pricing-notice {
	margin: 1.2em 0;
}

@media (any-hover: hover) {
	.food-menuitem:not(:hover) .share-menuitem {
		opacity: 0;
	}
}

@media (max-width: 1050px) {
	.food-menuitem {
		grid-template-areas: "name name name" "image image image" "description description description" " offers offers diets" " . share .";
		grid-template-rows: auto;
		grid-template-columns: 3em 1fr 3em;
		justify-content: center;
	}

	.menusection .menusection-header.sticky {
		position: static;
	}

	.food-menuitem .share-menuitem {
		padding: 0.6em;
	}
}

#order-call-btn {
	border-radius: 2rem;
	bottom: 1rem;
	left: 1rem;
	padding: .8rem 2rem;
	font-size: 1.5rem;
	text-decoration: none;
	transition: background-color 300ms ease-in;
}

#order-call-btn:hover, #order-call-btn:focus {
	background-color: var(--button-primary-active-background);
}
