@charset "UTF-8";
@import url("./vars.css");
@import url("https://cdn.kernvalley.us/css/normalize/normalize.css");
@import url("https://unpkg.com/@shgysk8zer0/core-css@2.5.3/rem.css");
@import url("https://unpkg.com/@shgysk8zer0/core-css@2.5.3/viewport.css");
@import url("https://unpkg.com/@shgysk8zer0/core-css@2.5.3/element.css");
@import url("https://unpkg.com/@shgysk8zer0/core-css@2.5.3/class-rules.css");
@import url("https://unpkg.com/@shgysk8zer0/core-css@2.5.3/cdn-fonts.css");
@import url("https://unpkg.com/@shgysk8zer0/core-css@2.5.3/animations.css");
@import url("https://unpkg.com/@shgysk8zer0/core-css@2.5.3/scrollbar.css");
@import url("https://unpkg.com/@shgysk8zer0/core-css@2.5.3/forms.css");
@import url("https://unpkg.com/@shgysk8zer0/core-css@2.5.3/accordion.css");
@import url("./layout.css");
@import url("./contact.css");
@import url("./menu.css");
@import url("./rooms.css");
@import url("./home.css");

@media (min-width: 801px) {
	:root:not([data-layout]) > body.grid, :root[data-layout="default"] > body.grid {
		grid-template-columns: 1fr minmax(var(--main-min-width,320px),var(--main-max-width,1200px)) minmax(var(--sidebar-min-width,300px),var(--sidebar-max-width,400px));
	}
}

#sidebar .ad-block:not(:last-of-type) {
	margin-bottom: 0.4em;
}

@media (min-width: 600px) {
	.img-container {
		float: left;
		margin: 0 0.7em 1.2em 0;
	}
}

@media (max-width: 599px) {
	.img-container {
		display: block;
		text-align: center;
	}
}

@media (prefers-color-scheme: light) {
	body.background-primary {
		background-color: #dfded9;
	}
}

:root[data-theme="light"] body.background-primary {
	background-color: #dfded9;
}

.skip-to-content-icon {
	animation: pointing 600ms alternate ease-in-out infinite;
}

@keyframes pointing {
	from {
		transform: none;
	}

	to {
		transform: translateY(0.6em);
	}
}
