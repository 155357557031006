#header {
	background-image: var(--hero-grad), url("/img/raster/background/snow-1440.jpg");
	min-height: 74vh;
	width: 100%;
	contain: strict;
	overflow: hidden;
	background-position-x: center;
}

#page-tab-nav:focus-within {
	width: 100%;
}

:root:not([data-background]) #header, :root[data-background="snow"] {
	background-position-y: bottom;
}

:root[data-background="front"] #header {
	background-image: var(--hero-grad), url("/img/raster/background/front-1440.jpg");
}

:root[data-background="fountain"] #header {
	background-image: var(--hero-grad), url("/img/raster/background/fountain-1440.jpg");
	background-position-y: top;
}

:root[data-background="sunrise"] #header {
	background-image: var(--hero-grad), url("/img/raster/background/sunrise-1280.jpg");
	background-position-y: bottom;
}

:root[data-background="motel"] #header {
	background-image: var(--hero-grad), url("/img/raster/background/motel-1440.jpg");
	background-position-y: 40%;
}

:root[data-background="restaurant"] #header {
	background-image: var(--hero-grad), url("/img/raster/background/restaurant-1440.jpg");
	background-position-y: 40%;
}

:root[data-background="rooms"] #header {
	background-image: var(--hero-grad), url("/img/raster/background/rooms-1440.jpg");
}

:root[data-background="lake"] #header {
	background-image: var(--hero-grad), url("/img/raster/background/lake-1280.jpg");
}

@media (max-width: 400px) {
	#header {
		background-image: var(--hero-grad), url("/img/raster/background/snow-400.jpg");
	}

	:root[data-background="front"] #header {
		background-image: var(--hero-grad), url("/img/raster/background/front-400.jpg");
	}

	:root[data-background="fountain"] #header {
		background-image: var(--hero-grad), url("/img/raster/background/fountain-400.jpg");
	}

	:root[data-background="sunrise"] #header {
		background-image: var(--hero-grad), url("/img/raster/background/sunrise-400.jpg");
	}

	:root[data-background="motel"] #header {
		background-image: var(--hero-grad), url("/img/raster/background/motel-400.jpg");
	}

	:root[data-background="restaurant"] #header {
		background-image: var(--hero-grad), url("/img/raster/background/restaurant-400.jpg");
	}

	:root[data-background="rooms"] #header {
		background-image: var(--hero-grad), url("/img/raster/background/rooms-400.jpg");
	}

	:root[data-background="lake"] #header {
		background-image: var(--hero-grad), url("/img/raster/background/lake-400.jpg");
	}
}

@media ((min-width: 401px) and (max-width: 800px)) {
	#header {
		background-image: var(--hero-grad), url("/img/raster/background/snow-800.jpg");
	}

	:root[data-background="front"] #header {
		background-image: var(--hero-grad), url("/img/raster/background/front-800.jpg");
	}

	:root[data-background="fountain"] #header {
		background-image: var(--hero-grad), url("/img/raster/background/fountain-800.jpg");
	}

	:root[data-background="sunrise"] #header {
		background-image: var(--hero-grad), url("/img/raster/background/sunrise-800.jpg");
	}

	:root[data-background="motel"] #header {
		background-image: var(--hero-grad), url("/img/raster/background/motel-800.jpg");
	}

	:root[data-background="restaurant"] #header {
		background-image: var(--hero-grad), url("/img/raster/background/restaurant-800.jpg");
	}

	:root[data-background="rooms"] #header {
		background-image: var(--hero-grad), url("/img/raster/background/rooms-800.jpg");
	}

	:root[data-background="lake"] #header {
		background-image: var(--hero-grad), url("/img/raster/background/lake-800.jpg");
	}
}

@media ((min-width: 801px) and (max-width: 1280px)) {
	#header {
		background-image: var(--hero-grad), url("/img/raster/background/snow-1280.jpg");
	}

	:root[data-background="front"] #header {
		background-image: var(--hero-grad), url("/img/raster/background/front-1280.jpg");
	}

	:root[data-background="fountain"] #header {
		background-image: var(--hero-grad), url("/img/raster/background/fountain-1280.jpg");
	}

	:root[data-background="motel"] #header {
		background-image: var(--hero-grad), url("/img/raster/background/motel-1280.jpg");
	}

	:root[data-background="restaurant"] #header {
		background-image: var(--hero-grad), url("/img/raster/background/restaurant-1280.jpg");
	}

	:root[data-background="rooms"] #header {
		background-image: var(--hero-grad), url("/img/raster/background/rooms-1280.jpg");
	}
}

#org-info {
	background-color: rgba(66, 1, 1, 0.55);
	color: #e1e1e1;
	padding: 0.6em;
	display: inline-block;
	position: absolute;
	top: 30%;
	right: 30vw;
	left: 30vw;
	border-radius: 8px;
	border: 1px solid currentColor;
	text-shadow: 2px 1px black;
	line-height: 1.6;
}

@media (max-width: 600px) {
	#org-info {
		left: 5vw;
		right: 5vw;
	}
}
