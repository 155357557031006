/* @import url("https://unpkg.com/@shgysk8zer0/core-css@2.5.3/theme/default/index.css"); */
@import url("https://unpkg.com/@shgysk8zer0/core-css@2.5.3/theme/properties.css");
@import url("https://unpkg.com/@shgysk8zer0/core-css@2.5.3/theme/default/index.css");

@media (max-width: 800px) {
	:root {
		--scrollbar-width: auto;
	}
}

:root {
	--accent-color-dark: #661E2A;
	--accent-color-light: var(--accent-color-dark);

	--button-primary-background-light: var(--accent-color-light);
	--button-primary-background-dark: var(--button-primary-background-light);
	--button-primary-hover-background-light: var(--button-primary-background-light);
	--button-primary-hover-background-dark: var(--button-primary-hover-background-light);
	--button-primary-active-background-light: #511721;
	--button-primary-active-background-dark: var(--button-primary-active-background-light);

	--viewport-height: 100vh;
	--sidebar-min-width: 400px;
	--sidebar-max-width: 420px;

	--scrollbar-track: var(--primary-color);
	--scrollbar-color: var(--accent-color);
	--map-width: 100%;

	--hero-grad: radial-gradient(ellipse at center, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 37%, rgba(0,0,0,0.65) 100%);

	--focus-outline-width: medium;
	--focus-outline-style: solid;
	--focus-outline-color: #1495d8;
	--focus-outline-offset: 4px;
}

@supports (height: 1dvh) {
	:root {
		--viewport-height: 100dvh;
	}
}

body {
	--map-width: 100%;
}
