.offer-container {
	grid-template-areas: "image offer offer" "image price price" "image . book";
	grid-template-columns: 348px 1fr auto;
	grid-gap: 12px;
	padding: 0 1.3em 0 0
}

.offer-container:not(:last-of-type) {
	margin-bottom: 1.3em;
}

.room-amenities-container svg {
	font-size: 1.1em;
	margin: 0.1em;
}

.room-image {
	grid-area: image;
	max-height: 348px;
}

.room-image > img {
	height: 100%;
}

.offer {
	grid-area: offer;
}

.room-price {
	grid-area: price;
}

.btn.book-button {
	grid-area: book;
	min-width: 210px;
	padding: 12px;
	transform: translate(-0.8em, -0.8em);
	vertical-align: middle;
	text-decoration: none;
	--link-color: var(--alt-color);
	transition: background-color 300ms ease-in;
}

.btn.book-button:hover, .btn.book-button:focus {
	background-color: var(--button-primary-active-background);
}

a.book-button.alt-color:hover, a.book-button.alt-color:visited:hover {
	color: var(--alt-color);
}

@media (max-width: 992px) {
	.offer-container {
		grid-template-areas: "image image image image" ". offer offer ." ". price price ." ". . . ." ". book book ." ". . . .";
		grid-template-columns: 1em 1fr 1fr 1em;
		grid-template-rows: 270px auto;
		padding: 0;
	}

	.btn.book-button {
		transform: none;
	}
}
