@media (min-width: 600px) {
	#contact-form, #faq {
		width: 80%;
		max-width: 800px;
		margin-left: 10%;
		margin-bottom: 1.3em;
	}
}

#contact-form, #faq {
	margin-bottom: 1.3em;
}

#contact-form .flex.center {
	justify-content: center;
}

#contact-form .btn-wide {
	font-size: 1.3em;
	padding: 0.6em 3em;
	border-radius: 6px;
	max-width: 300px;
}
