main {
	--link-color: #049ccf;
	--link-decoration: underline;
}

main [id] {
	scroll-margin-top: var(--nav-height, 3.5rem);
}

article p:first-letter {
	margin-left: 2em;
}

.krv-map {
	width: var(--map-width, 100%);
	height: var(--map-height, 400px);
	border: none;
}
