weather-current:defined, krv-events:defined {
	margin: 0 0 0.6em 0;
}

.ad-block {
	margin: 0 0 0.6em 0;
}

@media (max-width: 800px) {
	#sidebar {
		width: 95%;
		transform: translatex(2.5%);
	}

	#sidebar > *:first-of-type {
		margin-top: 0.6em;
	}
}

#sidebar {
	--ad-block-width: 30vw;
	--ad-block-stack-width: var(--ad-block-width);
	--ad-block-text-width: var(--ad-block-width);
	--ad-block-image-width: var(--ad-block-width);
}

#sidebar > weather-current, #sidebar > krv-events {
	width: 100%;
	max-width: 100%;
}

.ad-block .ad-label {
	font-size: 1.2rem;
	font-weight: bold;
	text-align: center;
}

@media (max-width: 600px) {
	#sidebar {
		--ad-block-width: 95vw;
	}
}
