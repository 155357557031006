#cards.grid {
	justify-content: space-evenly;
	grid-gap: 1em;
	grid-template-columns: repeat(auto-fill, minmax(300px, 350px));
	align-content: center;
	justify-items: center;
}

@media (max-width: 600px) {
	#cards.grid {
		grid-auto-flow: row;
	}
}

.section-link.card {
	padding: 0;
	display: inline-grid;
	max-width: 350px;
	text-decoration: none;
	transform: none;
	grid-template-areas: "image image image" ". label ." "description description description";
	grid-template-rows: 230px auto auto;
	transition: transform 600ms cubic-bezier(.68,-0.55,.27,1.55), filter 600ms cubic-bezier(.68,-0.55,.27,1.55);
}

.section-link:hover {
	transform: rotate(-5deg) scale(1.1);
	filter: drop-shadow(3px 3px 3px rgba(0,0,0,0.4));
}

.card-label {
	grid-area: label;
	margin: 0;
	font-size: 1.17em;
	font-weight: bold;
	display: block;
}

.card-img {
	grid-area: image;
	width: 100%;
	height: 230px;
	object-position: center;
}

.card-desc {
	grid-area: description;
}
